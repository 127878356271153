
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import {
  BIconCalendarFill,
  BIconHourglassSplit,
  BIconPeopleFill,
  BIconClipboardDataFill,
} from "bootstrap-icons-vue";
import { CSidebar } from "@coreui/vue";

export default defineComponent({
  name: "AppSidebar",
  components: {
    InlineSvg,
    BIconCalendarFill,
    BIconHourglassSplit,
    BIconPeopleFill,
    BIconClipboardDataFill,
    CSidebar,
  },
  computed: {
    sidebarUnfoldable() {
      return this.$store.state.theme.sidebarUnfoldable;
    },
    sidebarVisible() {
      return this.$store.state.theme.sidebarVisible;
    },
  },
});
