import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      name: "Home",
    },
    redirect: { name: "Calendar" },
    component: DefaultLayout,
    children: [
      {
        path: "kalendarz",
        name: "Calendar",
        meta: {
          name: "Kalendarz",
        },
        component: () =>
          import(
            /* webpackChunkName: "calendar" */ "@/views/calendar/CalendarView.vue"
          ),
      },
      {
        path: "/oczekujace-oferty",
        name: "ClientMasterList",
        meta: {
          name: "Oczekujące oferty",
        },
        component: () =>
          import(
            /* webpackChunkName: "client-master" */ "@/views/client-master/ClientMasterList.vue"
          ),
      },
      {
        path: "/moje-pary",
        name: "ClientList",
        meta: {
          name: "Moje pary",
        },
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/ClientList.vue"
          ),
      },
      {
        path: "/moje-pary/:id",
        name: "ClientView",
        meta: {
          name: "Szczegóły Pary Młodej",
        },
        component: () =>
          import(
            /* webpackChunkName: "client" */ "@/views/client/ClientView.vue"
          ),
      },
      {
        path: "statystyki",
        name: "Stats",
        meta: {
          name: "Statystyki",
        },
        component: () =>
          import(/* webpackChunkName: "stats" */ "@/views/stats/StatsView.vue"),
      },
      {
        path: "profil",
        name: "Profile",
        meta: {
          name: "Mój profil",
        },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/views/profile/ProfileView.vue"
          ),
      },
      {
        path: "zmiana-hasla",
        name: "PasswordChange",
        meta: {
          name: "Zmiana hasła",
        },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/views/profile/PasswordChangeView.vue"
          ),
      },
    ],
  },
  {
    path: "/logowanie",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/LoginView.vue"),
  },
  {
    path: "/resetowanie-hasla",
    name: "PasswordResetRequest",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@/views/auth/PasswordResetRequestView.vue"
      ),
  },
  {
    path: "/resetowanie-hasla/:token",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@/views/auth/PasswordResetView.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to, from) => {
  if (
    !store.getters["auth/isAuthenticated"] &&
    to.name !== "Login" &&
    to.name !== "PasswordResetRequest" &&
    to.name !== "PasswordReset"
  ) {
    return { name: "Login" };
  }
  if (store.getters["auth/isAuthenticated"] && to.name === "Login") {
    return { name: "Home" };
  }
});

export default router;
